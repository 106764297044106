import React, { useState } from 'react'
import '../styles/Header.css'
import { pathContants } from '../routes/pathConstants'

import { logo } from '../../assets'

function Header({page}) {
    let [shownav, setShowav] = useState(false);

    return (
        <header>
            <nav className='filtercont'>
                <a href={pathContants.HOME}><img className='logo' src={logo} alt='greens' onClick={() => {
                    sessionStorage.clear();
                }}/></a>
                <div className='searchbox' style={page === 'fill' ? {display: 'none'} : {display: 'block'}}>
                    <input type='text' />
                    <i className="fa fa-search" aria-hidden="true"></i>
                </div>
                <div className='bars' onClick={() => { shownav ? setShowav(false) : setShowav(true) }}>
                    <div className={shownav ? 'close' : 'bar'}></div>
                </div>
            </nav>
            <div className='navbody' style={shownav ? { display: 'block', visibility: 'visible'} : {display: 'none', visibility: 'hidden'} }>
                <ul>
                    <li><a href={pathContants.FILL}>Fill</a></li>
                    <li><a href={pathContants.VIEW}>View</a></li>
                </ul>
            </div>
        </header>
    )
}

export default Header
