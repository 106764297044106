import React from 'react'
import { Navigate } from 'react-router-dom'
import { pathContants } from './pathConstants'
import Layout from '../components/Layout'

function ProtectedRoute({ isAllowed, redirectTo = pathContants.HOME }) {

    if (isAllowed === 'true') {
        return (
            <Layout />
        )
    } else {
        return (
            <Navigate to={redirectTo} />
        )
    }
}

export default ProtectedRoute
