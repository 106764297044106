import './App.css';
import React, { useEffect, useState } from 'react'
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import { Home, routes } from './client/routes/routes';
import Page404 from './client/pages/Page404';

import { redirect } from 'react-router-dom';
import { pathContants } from './client/routes/pathConstants';
import ProtectedRoute from './client/routes/ProtectedRoute';

function App() {
 
  const [userlog, setUserLog] = useState(false);
  const [logged, setlogged] = useState('');

  useEffect(() => { 

    if (userlog) {
      sessionStorage.setItem('userlog', 'true');
    }

    setlogged(sessionStorage.getItem('userlog'));

    // console.log(pathContants.HOME);
   }, [userlog,logged])
  
  const baseurl = process.env.REACT_APP_BASE_URL;

  const router = createBrowserRouter([
    {
      path: pathContants.HOME,
      element: <Home />,
      errorElement: <Page404 />,
      action: async ({ params, request }) => {
        // console.log("Sending...");
        const response = await fetch(`${baseurl}api/sign`, {
          method: 'POST',
          body: await request.formData(),
        });
        const returned = await response.json();
        if (returned.Status === 200) {
          // console.log(returned.message);
          setUserLog(true);
          sessionStorage.setItem('userlog', 'true');
          return redirect(pathContants.FILL);
        } else if (returned.Status === 400) {
          // console.log(returned.message);
          return returned.Status;
        }
        // if (!response.ok) throw response;
        
        // return null;
      }
    },
    {
      element: <ProtectedRoute isAllowed={sessionStorage.getItem('userlog')} />,
      errorElement: <Page404 />,
      children: routes
    }
  ]
    
  )
  return (
    
    <RouterProvider router={router} />
  );
}

export default App;
