import React from 'react'
import { pathContants } from './pathConstants';

export const Home = React.lazy(() => import('../pages/Home'));
const Fill = React.lazy(() => import('../pages/Fill'));
const View = React.lazy(() => import('../pages/View'));

export const ProtectedRoute = import('./ProtectedRoute');

const baseurl = process.env.REACT_APP_BASE_URL;

export const routes = [
    
    { path: pathContants.FILL, element: <Fill />, action: async ({ params, request }) => {
        console.log("Sending...");
        let body = await request.formData();
        let intent = body.get('intent');

        console.log(intent);
        if (intent === 'single') {
            // const formdata = await request.formData();
            // console.log(formdata);
            // const formdata = await request.formData();
        
            const response = await fetch(`${baseurl}api/fill`, {
                method: 'POST',
                body: body,
            });
        
            const returned = await response.json();
            if (returned.Status === 200) {
                console.log("Success");
                // console.log(this);
                
            }

            // if (!response.ok) throw response;
            return returned.message;
        } else {
            const response = await fetch(`${baseurl}api/multiupload`, {
                method: 'POST',
                body: body,
            });
        
            const returned = await response.json();
            if (returned.Status === 200) {
                console.log("Success");
                // console.log(this);
                
            }

            // if (!response.ok) throw response;
            return returned.message;
        }
    }},
    {
        path: pathContants.VIEW, element: <View />, loader: async (req, res) => {
            const viewdata = await fetch(`${baseurl}api/getdetails`, {
                method: 'GET',
            }); 

            const returned = await viewdata.json();

            return returned;
    } },
]